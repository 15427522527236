import React, { useEffect } from "react"
import { graphql } from 'gatsby';

import "../../styles.css"
import VideoPlayer from "../components/sections/videoPlayer"
import CommonPageRenderer from "../components/pageRendererHoc/CommonPageRenderer";
import { ANALYTICS_CONSTANTS } from "../constants";
import { trackingOnThankyouPage } from "../../utils/redirectCheck";
const ThankyouPage = ({ data }) => {
	const seoFields = data?.allWpPage?.edges?.[0]?.node?.thankYouPage?.seofield;

	useEffect(()=>{
		trackingOnThankyouPage();
	},[])

	return (
		<div className="thank-you-page-container">
			<CommonPageRenderer
				description={seoFields?.description}
				keyWords={seoFields?.keywords}
				title={seoFields?.title}
				canonical={seoFields?.canonicaltag}
				favIcon={seoFields?.favicon?.localFile?.url}
				fbEventName={ANALYTICS_CONSTANTS.FB_EVENT_THANKYOU_PAGE}
				pageName={ANALYTICS_CONSTANTS.THANK_YOU_PAGE_NAME}>
				<VideoPlayer />
			</CommonPageRenderer>

		</div >
	)
}

export const query = graphql`
{
	allWpPage(filter: {uri: {eq: "/thank-you/"}}) {
	  edges {
		node {
		  id
		  thankYouPage {
			seofield {
			  canonicaltag
			  description
			  title
			  favicon {
				id
			  }
			  fieldGroupName
			  keywords
			}
		  }
		}
	  }
	}
  }
`;
export default ThankyouPage
